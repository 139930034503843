import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/cms/:node?',
      name: 'cms',
      meta: { requiresRole: ['supusr', 'cms'] },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/CMS/CMS.vue')
    },
    {
      path: '/mapper',
      name: 'mapper',
      meta: { requiresRole: ['supusr'] },
      component: () => import('../views/Mapper/Mapper.vue')
    },
    {
      path: '/automapper/:dealershipId?',
      name: 'automapper',
      meta: { requiresRole: ['supusr'] },
      component: () => import('../views/Automapper/Automapper.vue')
    },
    {
      path: '/features',
      name: 'features',
      component: () => import('../views/FeatureFlags/FeatureFlags.vue')
    },
    {
      path: '/dealerships',
      meta: { requiresRole: ['supusr'] },
      children: [
        {
          path: '',
          name: 'dealerships',
          meta: { requiresRole: ['supusr'] },
          component: () => import('../views/Dealerships/Dealerships.vue')
        },
        {
          path: 'edit/:id',
          name: 'editDealership',
          meta: { requiresRole: ['supusr'] },
          component: () => import('../views/Dealerships/EditDealership.vue')
        },
        {
          path: 'new',
          name: 'newDealership',
          meta: { requiresRole: ['supusr'] },
          component: () => import('../views/Dealerships/NewDealership.vue')
        }
      ]
    },
    {
      path: '/targetsites/:id?',
      name: 'targetsites',
      component: () => import('../views/TargetSites/TargetSites.vue'),
      meta: { requiresRole: ['supusr'] }
    }
  ]
})

async function hasRole(roles) {
  const user = await window.seezSdk.getCurrentUser()
  return user != null && user.roles.some(r => roles.includes(r.rolecode))
}

router.beforeResolve(async (to, _, next) => {
  if (to.meta.requiresRole) {
    if (await hasRole(to.meta.requiresRole)) next()
    else {
      await window.seezSdk.showLogin()
      if (await hasRole(to.meta.requiresRole)) next()
      else next('/')
    }
  } else next()
})

export default router
