<template>
  <div id="homePage">
    <h1>Seez Admin</h1>
  </div>
</template>
<script>
export default {
  name: 'HomeView'
}
</script>
<style>
#homePage {
  height: 100%;
  display: grid;
  place-items: center;
}
</style>
