<template>
  <main>
    <RouterView />
  </main>
  <footer />
  <header>
    <RouterLink active-class="active" to="/"><Logo class="seezLogo" /></RouterLink>
    <nav>
      <RouterLink active-class="active" to="/cms">CMS</RouterLink>
      <RouterLink active-class="active" to="/features">Features</RouterLink>
      <RouterLink active-class="active" to="/dealerships">Dealerships</RouterLink>
      <RouterLink active-class="active" to="/targetsites">Target Sites</RouterLink>
      <RouterLink active-class="active" to="/mapper">Mapper</RouterLink>
      <RouterLink active-class="active" to="/automapper">Automapper</RouterLink>
    </nav>
    <button @click="userClick" :title="currentUser?.name ?? 'Login'">
      <UserIcon />
    </button>
  </header>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import Logo from './assets/logo.svg'
import UserIcon from './assets/user.svg'
export default {
  name: 'RootApp',
  components: { Logo, RouterLink, RouterView, UserIcon },
  data() {
    return {
      currentUser: null
    }
  },
  created() {
    window.seezSdk.getCurrentUser().then(r => (this.currentUser = r))
  },
  methods: {
    async userClick() {
      if (this.currentUser) {
        window.seezSdk.showLogout()
      } else {
        window.seezSdk.showLogin()
      }
    }
  }
}
</script>

<style>
#app {
  display: grid;
  grid-template:
    'header' auto
    'content' 1fr
    'footer' auto;

  > header {
    grid-area: header;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--background);
    box-shadow: var(--shadow);
    place-items: center;

    .seezLogo {
      height: 1.5rem;
    }

    > nav {
      display: flex;
      gap: 1rem;
      overflow: auto;
      position: absolute;
      width: 100vw;
      bottom: 0;
      background: var(--background);
      padding: 1rem 0;
      border-top: 1px solid var(--border-color);

      @media screen and (min-width: 50rem) {
        position: initial;
        width: auto;
        background: transparent;
        padding: 0;
        border-top: none;
      }

      > a {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        white-space: nowrap;

        &.active {
          background-color: var(--accented-background);
        }
      }
    }

    > button {
      border: none;
      background: none;
      cursor: pointer;
      margin-inline-start: auto;

      > svg {
        height: 2em;
      }
    }
  }

  > main {
    grid-area: content;
    overflow: auto;
    margin-bottom: 5rem;
    @media screen and (min-width: 50rem) {
      margin-bottom: 0;
    }
  }

  > footer {
    grid-area: footer;
    padding: 1rem;
    box-shadow: var(--shadow);
    background-color: var(--background);

    &:empty {
      display: none;
    }
  }
}
</style>
